.container h1{
    font-size: 2em;  /* Adjust size as needed */
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.container h2{
    font-size: 1em;  /* Adjust size as needed */
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.container h3{
    font-size: 1em;  /* Adjust size as needed */
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.container h4{
    font-size: 1em;  /* Adjust size as needed */
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.container p{
    color: #000000;
    margin-bottom: 20px;
}
.container{
    background-color: #ffffff;
    width: 100%;
}
.container li{
    font-style: italic;
    list-style-type: disc;
}

.container ol{
    list-style-type: disc;

}

.container ul{
    list-style-type: disc;
    margin-left: 50px;
}